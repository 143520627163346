import React from "react"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import SEO from "../components/seo"
import Iskur from "../images/denizli-iplik-firmalar.jpg"

const Products = () => (
  <Layout>
    <SEO title="Products" />

    <PageHeader title="Products" />

    <div className="h-100 py-4">
      <div className="main pb-2 w-full text-gray-700">
        <article className="mb-5 container mx-auto">
          <div className="w-full px-10 text-lg">
            <p className="pt-10 pb-5">
              As MRC Tekstil, our journey as a Yarn Trader in Denizli begins in
              1842, our founding date. We continue our service by supplying yarn
              from domestic and foreign companies to Denizli textile industry.
            </p>
            <p className="pb-5">
              Since 2011, we have been the dealer of the yarns produced by the
              ISKUR (Maraş) company. We are confident that you will find the
              quality you are looking for in our yarn varieties produced by
              ISKUR.
            </p>
          </div>

          <div className="w-full px-10 flex justify-center">
            <img src={Iskur} alt="denizli iplik firmaları" />
          </div>

          <div className="w-full p-10 prose prose-md md:prose-lg">
            <ul>
              <li>Coarse count Denim Yarn</li>
              <li>Core-Yarn</li>
              <li>Slub Yarn</li>
              <li>Carded Yarn in the range of Ne 20/1 to Ne 40/1</li>
              <li>Combed and Supima in the range Ne 20/1 to Ne 80/1</li>
              <li>Viscose Yarn</li>
              <li>Tencel Yarn</li>
              <li>Organic Yarn</li>
            </ul>

            <p>
              <strong>100% Cotton Open End Weaving Yarn</strong>
            </p>

            <ul>
              <li>Ne 6/1 to 30/1 Open End Yarn</li>
              <li>Ne 6/1 to 20/1 Open End Slub Yarn</li>
            </ul>
            <p>
              <strong>
                100% Cotton Ring Carded, Slub and Lycra Weaving Yarn
              </strong>
            </p>
            <ul>
              <li>Ne 6/1 to Ne 30/1 Ring Carded Yarn</li>
              <li>Ne 6/1 to Ne 30/1 Ring Carded Slub Yarn</li>
              <li>Ne 10/1 to Ne 30/1 Ring Carded Cotton Lycra Yarn</li>
            </ul>

            <p>
              <strong>
                100% Cotton Combed, Compact and Lycra Weaving Yarn
              </strong>
            </p>
            <ul>
              <li>Ne 14/1 to Ne 60/1 Combed Yarn</li>
              <li>Ne 16/1 to Ne 40/1 Combed Cotton Lycra Yarn</li>
            </ul>

            <p>
              <strong>
                In addition,the yarn types produced in our RING facility :
              </strong>
            </p>

            <ul>
              <li>Better Cotton Yarn Types</li>
              <li>100% Tencel Yarn</li>
              <li>Tencel / Cotton Blended Yarn</li>
              <li>100% Viscose Yarn</li>
              <li>Viscose / Cotton Blended Yarn</li>
              <li>100% Bambo Yarn</li>
              <li>Bambo / Cotton Blended Yarn</li>
              <li>Polyester / Viscose Blended Yarn – Ecru, Grey and Colored</li>
              <li>100% Pima Yarn</li>
              <li>Organic Yarn</li>
              <li>100% Cotton Melange Yarn – Grey and Colored</li>
              <li>100% Viscose Melange Yarn – Grey and Colored</li>
              <li>Cotton / Polyester Blended Yarn – Ecru, Grey and Colored</li>
              <li>Ne 30/1 to Ne 60/1 Combed Compact Weaving Yarn</li>
            </ul>
          </div>

          <div className="w-1/2">
            <div className="col-span-1"></div>

            <div className="col-span-1"></div>
          </div>

          <div className="prose prose-md"></div>
        </article>
      </div>
    </div>
  </Layout>
)

export default Products
